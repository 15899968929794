<script setup lang="ts"></script>

<template>
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <a href="/"
      ><img
        class="mx-auto h-10 w-auto"
        src="/img/emfesta-logo.png"
        alt="Emfesta Logo"
    /></a>
  </div>
</template>
