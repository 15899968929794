<script setup lang="ts">
  import FacebookLoginButton from '~/components/buttons/FacebookLoginButton.vue'
  import GoogleLoginButton from '~/components/buttons/GoogleLoginButton.vue'

  const path = useRoute().path
  const text = path === '/entrar' ? 'Ou entre com' : 'Ou registre-se com'
</script>

<template>
  <div>
    <div class="relative mt-10">
      <div
        class="absolute inset-0 flex items-center"
        aria-hidden="true">
        <div class="w-full border-t border-gray-200" />
      </div>
      <div class="relative flex justify-center text-sm font-medium leading-6">
        <span class="bg-white px-6 text-gray-900">{{ text }}</span>
      </div>
    </div>

    <div class="mt-6 grid grid-cols-2 gap-4">
      <FacebookLoginButton />
      <GoogleLoginButton />
    </div>
  </div>
</template>
