<script setup lang="ts">
  import AuthenticationService from '~/services/AuthenticationService'
  import UserRole from '~/models/enums/UserRole'

  const userRole =
    (useRoute().query.role?.toString() as UserRole) ?? UserRole.USER

  async function loginWithFacebook() {
    await AuthenticationService.loginWithFacebook(userRole)
  }
</script>

<template>
  <a
    href="#"
    class="flex w-full items-center justify-center gap-3 rounded-md border bg-[#3b5998] px-3 py-1.5 text-white shadow focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
    @click="loginWithFacebook">
    <svg
      class="h-5 w-5"
      aria-hidden="true"
      fill="currentColor"
      viewBox="0 0 20 20">
      <path
        d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
    </svg>
    <span class="text-sm font-semibold leading-6">Facebook</span>
  </a>
</template>
