<script setup lang="ts">
  import SocialLogin from '~/components/authentication/SocialLogin.vue'
  import AuthenticationHeader from '~/components/authentication/AuthenticationHeader.vue'

  useHead({
    htmlAttrs: {
      class: 'h-full bg-white',
    },
    bodyAttrs: {
      class: 'h-full',
    },
  })

  const path = useRoute().path
  const showSocialLogin = path === '/registrar' || path === '/entrar'
</script>

<template>
  <div
    class="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
    <SiteBgGuides />
    <AuthenticationHeader />
    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
      <div class="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
        <slot />
        <SocialLogin v-if="showSocialLogin" />
      </div>
    </div>
    <LazyAuthenticationFooter />
  </div>
</template>
